// import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
// import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// import TextField from '@material-ui/core/TextField';
import ToggleFullscreenButton from '../ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
// import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import { useHistory } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
// import { Typography } from '@material-ui/core';

import logoConecta from './logo-prospera.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // backgroundColor: theme.palette.background.default,
      background: '#0C0E3D'
    },
    rightButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
  })
);

export default function MenuBar() {
  const history = useHistory();
  const classes = useStyles();
  // const { URLRoomName } = useParams();
  // const { user, getToken, isFetching } = useAppState();
  const { isFetching } = useAppState();
  const { isConnecting, connect, localTracks } = useVideoContext();
  const roomState = useRoomState();

  // const [name, setName] = useState<string>(user?.displayName || '');
  // const [roomName, setRoomName] = useState<string>('');
  const [hasConnected, setHasConnected] = useState(false);

  const [logoPartner] = useState(() => {
    const partnerStringData = localStorage.getItem('@partner');
    if (partnerStringData) {
      const partnerData = JSON.parse(partnerStringData);
      return partnerData.logo_url;
    }
    return '';
  });

  // this effect will make auto connection based on local storage token
  useEffect(() => {
    if (hasConnected) return;

    console.log('Effect do menubar');
    console.log('LocalTracks: ', localTracks);

    const token = localStorage.getItem('@token');
    if (!token) {
      history.replace('/login');
    } else {
      if (localTracks && localTracks.length === 2) {
        // o length deve ser igual dois pois espera-se duas tracks nesse ponto,
        // a track do video e a track de audio.
        console.log('Proceed to connection ...');
        setHasConnected(true);
        connect(token);
      }
    }
  }, [connect, hasConnected, history, localTracks]);

  // useEffect(() => {
  //   if (URLRoomName) {
  //     setRoomName(URLRoomName);
  //   }
  // }, [URLRoomName]);

  // const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setName(event.target.value);
  // };

  // const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRoomName(event.target.value);
  // };

  // const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
  //   if (!window.location.origin.includes('twil.io')) {
  //     window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}`));
  //   }
  //   getToken(name, roomName).then(token => connect(token));
  // };

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar>
        {roomState === 'disconnected'
          ? // <form className={classes.form} onSubmit={handleSubmit}>
            //   {!user?.displayName ? (
            //     <TextField
            //       id="menu-name"
            //       label="Nome"
            //       className={classes.textField}
            //       value={name}
            //       onChange={handleNameChange}
            //       margin="dense"
            //     />
            //   ) : (
            //     <Typography className={classes.displayName} variant="body1">
            //       {user.displayName}
            //     </Typography>
            //   )}
            //   <TextField
            //     id="menu-room"
            //     label="Sala"
            //     className={classes.textField}
            //     value={roomName}
            //     onChange={handleRoomNameChange}
            //     margin="dense"
            //   />
            //   <Button
            //     type="submit"
            //     color="primary"
            //     variant="contained"
            //     disabled={isConnecting || !name || !roomName || isFetching}
            //   >
            //     Entrar na sala
            //   </Button>
            // </form>
            (isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />
          : null}
        <img src={logoPartner || logoConecta} style={{ marginLeft: 10 }} alt="logo dr conecta" />
        <div className={classes.rightButtonContainer}>
          {
            <button
              style={{ backgroundColor: '#0eb14b', color: '#FFF', borderRadius: 4, border: 0, height: 28, position: 'relative', left: '5px' }}
              // eslint-disable-next-line no-restricted-globals
              onClick={() => location.reload()}
            >
              RECARREGAR
            </button>
          }
          <ToggleFullscreenButton />
          {/* <Menu /> */}
        </div>
      </Toolbar>
    </AppBar>
  );
}
