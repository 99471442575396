import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import logoConecta from './logo-prospera.svg';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#FFF',
  },
  header: {
    height: 64,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    boxSizing: 'content-box',
    background: '#0C0E3D'
  },
  banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    background: '#fff',
    boxShadow: 'inset 0 20px 20px -25px rgba(0,0,0,0.8)',
    color: '#FFF',
    '& h1': {
      color: '#4D4D4D',
      fontSize: 48,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    '& p': {
      fontSize: 18,
      color: '#4D4D4D',
      textAlign: 'center',
    },
  },
  link: {
    color: '#4D4D4D',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function EntryPage({ title = 'Telemedicina Prospera Saúde' }) {
  const classes = useStyles();

  const [partnerData] = useState(() => {
    const partnerStringData = localStorage.getItem('@partner');
    if (!partnerStringData) return null;
    return JSON.parse(partnerStringData);
  });

  return (
    <ThemeProvider theme={theme}>
      <main className={classes.container}>
        {/* Top Header */}
        <header className={classes.header}>
          <img src={partnerData ? partnerData.logo_url : logoConecta} alt="logo" />
        </header>

        {/* Gradient Banner */}
        <div className={classes.banner}>
          <h1>{title}</h1>
          <p>
            Para qualquer dúvida, entre em contato com:{' '}
            <a
              className={classes.link}
              href={`mailto:${partnerData ? partnerData.contact_email : 'atendimento@drconecta.com.br'}`}
            >
              {partnerData ? partnerData.contact_email : 'contato@prosperasaude.com.br'}
            </a>
          </p>
        </div>
      </main>
    </ThemeProvider>
  );
}
